<template>
    <div class="row" v-scroll="handleScroll">
        <div class="custom-user-banner col-xs-12 col-sm-12" v-if="items">
            <carousel-custom-banners
                v-if="typeof (items.wideBanners) !== 'undefined'"
                :autoplay="true"
                :autoplayTimeout="7000"
                :items="1"
                :loop="items.wideBanners.length > 1 ? true:false"
                :dots="false"
                :nav="false"
            >
                <a
                        :href="item.style.link"
                        v-bind:key="item.id"
                        v-for="item in items.wideBanners"
                        rel="nofollow"
                        target="_blank"
                        class="result-banner"
                        :style="getBg(item.style.bg)"
                >
                    <div :class="'shadow ' + item.style.head.position">
                        <div
                                class="head"
                                :style="'color:' + item.style.head.color + '; text-align:' + item.style.head.position"
                                v-html="item.style.head.text"
                        >
                        </div>
                        <div
                                class="desc"
                                :style="'color:' + item.style.desc.color + ';text-align:' + item.style.desc.position"
                                v-html="item.style.desc.text ? item.style.desc.text:''"
                        >
                        </div>
                    </div>
                </a>
            </carousel-custom-banners>
        </div>
        <div class="" v-else>
            <br>
                <div class="spinner-main-photo">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                </div>
            <br>
        </div>
    </div>
</template>

<script>
    import Carousel from 'vue-owl-carousel'
    import {mapState} from 'vuex'
    import {scrollFunc} from "../mixins/common";

    export default {
        name: "UserBanners",
        mixins: [scrollFunc],
        components: {
            'carousel-custom-banners': Carousel
        },
        computed: {
            ...mapState('Banners', {
                items: state => state.userBanners
            }),

        },
        created() {
        },
        methods:{
            getBg(value){
                let str = '' + value
                str = str.replace('http://', 'https://')
                let style = {
                    backgroundImage: str,
                    height: '100px'
                }
                return style
            },
            handleScroll(evt, el){
                if(this.isScrolled(evt, el) && this.items === false){
                    this.$store.commit('Banners/setUserBanners', [])
                    this.$store.dispatch('Banners/getUserBanners')
                }
            }
        }
    }
</script>

<style lang="less">
    .result-banner{
        background-size: contain;
        background-repeat: no-repeat;
    }
</style>
