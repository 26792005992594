<template>
    <div class="col-md-4 hidden-xs" id="block-contest">
        <div class="kris-konkurs">
            <div class="kris-konkurs-head mainHead">
                <div class="l1">
                    <div class="line"></div>
                </div>
                <div class="l2">
                    <div class="h2">
                        <span>Конкурсы</span>
                        <a href="/contest/">Все конкурсы</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="item" id="contest-react" class="col-md-12 col-xs-12 col-sm-12 col-lg-12 kris-konkurs-txt">
                    <div className="kris-konkurs-img">
                        <a :href="'/contest/?nid=' + item.id">
                            <img :src=" api.host + '/uploads/adverts/preview/' + item.preview" width="100%" />
                        </a>
                    </div>
                    <div className="h2">
                        <a :href="'/contest/?nid=' + item.id">
                            {{item.title}}
                        </a>
                    </div>
                    <p v-html="item.anons"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "Contest",
        computed:{
            ...mapState('Adverts', {
                item: state => state.contest,
                api: state => state.api,
            })
        },
        created(){
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>
