<template>

        <div class="kris-news-top">
            <span class="data" v-if="date">
                {{date}}
            </span>
            <div class="desc-news">
                <div class="h3">
                    <a class="link" :href="this.link" target="_blank" v-html="this.title"></a>
                </div>
                <div class="kris-news-bot" v-html="this.anons"></div>
            </div>
        </div>

</template>

<script>
    export default {
        name: "newsItem",
        props: [
            "date",
            "title",
            "link",
            "anons",
        ],
    }
</script>

<style scoped>

</style>
