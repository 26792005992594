<template>
    <div class="">
        <loading v-if="data === null" />
        <transition name="fade">
        <div class="important-article" v-if="data">
            <div class="inner" >
                <span v-html="data.anons"></span>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
  import {IMPORTANT_ARTICLE_MODULE} from "../store/modules/modules.types";
  import {GET_IMPORTANT_ARTICLE} from "../store/modules/any/actions.types";
  import {mapState} from 'vuex'
  import Loading from "./Loading";

  export default {
    name: "ImportantArticle",
    computed:{
      ...mapState(IMPORTANT_ARTICLE_MODULE, {
            data:  state => state.data
        })
    },
    components: {
      'loading': Loading
    },
    mounted() {
      this.$store.dispatch(IMPORTANT_ARTICLE_MODULE + '/' + GET_IMPORTANT_ARTICLE)
    }
  }
</script>

<style scoped>

</style>
