import Axios from 'axios'
import {GET_IMPORTANT_ARTICLE} from "./actions.types";

export const importantAdvert = {
    namespaced: true,
    state: {
        data: null,
    },
    mutations:{
        setData(state, value){
            state.data = value
        }
    },
    actions:{
        [GET_IMPORTANT_ARTICLE]({commit,rootState}){
            return Axios.get(rootState.host + '/api/important/article.json').then((res) => {
                if (res.data.response === 'success') {
                    commit('setData', res.data.data)
                } else {
                    commit('setData', false)
                }
            }).catch(()=>{
                commit('setData', false)
            })
        }
    }
}

export const photosOnMain = {
    namespaced: true,
    state:{
        path: false,
        data: false
    },
    mutations:{
        setPath(state, value){
            state.path = value
        },
        setData(state, value){
            state.data = value
        }
    },
    actions:{
        getData({state, commit, rootState}){
            if(state.path){
                Axios({
                    url: state.path,
                    method: 'get'
                }).then((res) => {
                    commit('setData', res.data)
                })
            }
        }
    },
}
