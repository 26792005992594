<template>
    <div class="row" id="big-main-banners-react">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 nopadding" >
            <carousel v-if="items"
                      :lazyLoad="true"
                      :autoplay="true"
                      :autoplayTimeout="7000"
                      :items="1"
                      :loop="true"
                      :dots="true"
                      :nav="false"
            >
                <a class="slide"
                   v-bind:key="item.id"
                   v-for="item in items"
                   :href="item.link"
                   target="_blank"
                   rel="nofollow"
                   @click.stop="analitics"
                >
                    <img :src="api.main + '/' + item.file" />
                </a>
            </carousel>

        </div>
    </div>
</template>

<script>
    import Carousel from 'vue-owl-carousel'
    import {mapState} from 'vuex'
    export default {
        name: "Slider",
        components: {
            carousel: Carousel
        },
        computed:{
            ...mapState('Banners', {
                items: 'slides',
                api: 'api'
            })
        },
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                },
            }
        },
        created(){
            this.$store.dispatch('Banners/getSlides')
        },
        methods:{
            analitics(e){
                // this.$ga.event({
                //     eventCategory: 'outbound',
                //     eventAction: 'click',
                //     eventLabel: e.target.parentElement.getAttribute('href')
                // });
            }
        }
    }
</script>

<style lang="less">
    .agile{
        position: relative;
        .agile__actions{
            box-sizing: border-box;
            position: relative;
            margin: 10px 0;
            text-align: center;
            .agile__dots{
                width: 100%;
                display: -webkit-flex;
                display: flex;
                flex-flow: row wrap;
                list-style: none;
                justify-content:center;
                .agile__dot{
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: 0 5px;
                    padding: 0;
                    cursor: pointer;
                    &.agile__dot--current{
                        button{
                            &:before {
                                color: black;
                                opacity: 0.75;
                            }
                        }
                    }
                    button{
                        font-size: 0;
                        line-height: 0;
                        display: block;
                        padding: 5px;
                        cursor: pointer;
                        color: transparent;
                        border: 0;
                        outline: 0;
                        background: 0 0;
                        &:before{
                            color: #869791;
                            opacity: 0.25;
                            font-size: 40pt;
                            line-height: 20px;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 20px;
                            height: 20px;
                            content: "•";
                            text-align: center;
                            color: rgb(0, 0, 0);
                        }
                    }
                }
            }
        }
    }
</style>
