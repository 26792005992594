import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import Slider from './components/Slider'
import SmallUserBanners from './components/SmallUserBanners'
import router from './router'
import store from './store'
import { createProvider } from './vue-apollo'
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//     id: 'UA-92968160-1',
//     debug: true
// })

Vue.config.productionTip = false

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
})

new Vue({
    router,
    store,
    render: h => h(Slider)
}).$mount('#big-main-banners-react')

new Vue({
    router,
    store,
    apolloProvider: createProvider(),
    render: h => h(App)
}).$mount('#app-page-index')

new Vue({
    router,
    store,
    render: h=>h(SmallUserBanners)
}).$mount('#small-user-banners')
