<template>
    <div v-if="isFaqNew()">
        <div v-if="itemsFaqNew">
            <div v-if="itemsFaqNew.length > 0">
                <faq-header link="/elektronnye_servisy/vopros-otvet-new/" />
                <ul class="faq-main">
                    <question
                        v-bind:key="item.id"
                        v-for="item in itemsFaqNew"
                        :date="getDateRuFromDate(item.confirmDate)"
                        :question="item.question"
                    />
                </ul>
                <a class="faq-all" href="/elektronnye_servisy/vopros-otvet-new/" title="Посмотреть ответы">Посмотреть ответы</a>
            </div>
            <div class="" v-else>
                <a className="faq-banner" href="/elektronnye_servisy/vopros-otvet-new/" title="Задать вопрос" >
                    <img src="https://static.mskobr.ru/img/faq_banner.jpg" width="100%" />
                </a>
            </div>
        </div>
        <div class="" v-else>

        </div>
    </div>
    <div v-else>
        <div class="" v-if="items">
            <transition name="fade">
            <div v-if="items.length>0">
                <faq-header link="/elektronnye_servisy/vopros-otvet/" />
                <ul class="faq-main">
                    <question v-bind:key="item.id" v-for="item in items" :date="getDateRu(item.publish_time)" :question="item.question" />
                </ul>
                <a class="faq-all" href="/elektronnye_servisy/vopros-otvet/" title="Посмотреть ответы">Посмотреть ответы</a>
            </div>
            <div v-else>
                <a className="faq-banner" href="/elektronnye_servisy/vopros-otvet/" title="Задать вопрос" >
                    <img src="https://static.mskobr.ru/img/faq_banner.jpg" width="100%" />
                </a>
            </div>
            </transition>
        </div>
        <div class="" v-else>
            <div class="spinner-main-photo">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { dateFunc } from '../../mixins/common'
    import Header from './Header'
    import Question from './Question'
    export default {
        name: "Questions",
        components: {
            'faq-header': Header,
            'question': Question
        },
        mixins:[ dateFunc ],
        computed:{
            ...mapState("faqNew", {
                itemsFaqNew: state => state.questionsOnMain
            }),
            ...mapState({
                items: 'dataQuestions',
                eoId: 'eoId',
                faqNewIds: 'faqNewIds'
            })
        },
        created(){
            if (this.isFaqNew()) {
                this.$store.dispatch('faqNew/getQuestionsOnMain')
            }
            this.$store.dispatch('getDataQuestions')
        },
        methods:{
            isFaqNew(){
                return this.faqNewIds.includes(parseInt(this.eoId))
            }
        }
    }
</script>

<style scoped>

</style>
