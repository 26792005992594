<template>
    <div class="block-dispatch">
        <form id="dispatch-form" method="post" name="sbs_frm" @submit.prevent.stop="onSubmit" :action="link">
            <label for="dispatch-name">
                Подпишитесь <span class="hidden-xs">на новости и объявления</span>
            </label>
            <input id="dispatch-name" type="text" name="email" placeholder="Введите e-mail" v-model="email" />
<!--            <input v-if="link == ''" onclick="$('#form-sub').modal('show');$('#user-email').val($('#dispatch-name').val()); return false;" id="subscribe-submit" type="submit" value="" name="send" />-->
            <input id="subscribe-submit" type="submit" value="" name="send" :disabled="!email ? true:false" />

        </form>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {SET_NEWSLETTER_EMAIL} from "../store/mutations.types";
    export default {
        name: "Dispatch",
        data(){
          return {
            email:null
          }
        },
        computed:{
            ...mapState({
                link: 'dispatchLink'
             })
        },
      methods:{
        onSubmit(){
          this.$store.commit(SET_NEWSLETTER_EMAIL, this.email)
          this.$router.push('/captcha')
        }
      }
    }
</script>

<style scoped>

</style>
