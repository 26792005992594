<template>
    <div class="">
        <important-article />
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-8 col-sm-12" style="padding-left:0px;">
                    <div id="block-tabs">
                        <div id="nav">
                            <ul class="nav nav-tabs" id="title-tabs-main">
                                <li  :class="$route.path == '/' ? 'active':''" role="presentation">
                                    <router-link to="/" style="padding: 13px 15px;">Новости</router-link>
                                </li>
                                <li :class="$route.path == '/adverts' ? 'active':''">
                                    <router-link to="/adverts" style="padding: 13px 15px;">Объявления</router-link>
                                </li>
                                <li>
                                    <a href="https://www.mos.ru/city/projects/covid-19/" class="new-tab" target="_blank ">ДОСТОВЕРНО<br> о COVID-19</a>
                                </li>
                                <!--<li :class="$route.path == '/news-today' ? 'active':''">
                                    <router-link to="/news-today" class="new-tab" >Новости<br/> большого города</router-link>
                                </li>-->
                                <li :class="$route.path == '/pupil-saturday' ? 'active':''">
                                    <router-link to="/pupil-saturday" class="new-tab" >Субботы московского<br/> школьника</router-link>
                                </li>
                            </ul>
                        </div>
                        <div id="tabs-on-main" class="tab-content container-fluid">
                            <router-view/>
                        </div>
                        <dispatch />
                    </div>
                </div>

                <div class="col-md-4 col-sm-12" id="block-faq">
                    <questions />
                </div>
            </div>
        </div>
        <user-banners />
        <adverts-dogm-contests />
        <photos-on-main />
        <small-banners />
    </div>
</template>

<script>
    import Questions from './components/questions/Questions'
    import Dispatch from './components/Dispatch'
    import UserBanners from './components/UserBanners'
    import PhotosOnMain from './components/PhotosOnMain'
    import SmallBanners from './components/SmallBanners'
    import AdvertsDogmContests from './components/AdvertsDogmContests'
    import ImportantArticle from "./components/ImportantArticle";
    import {IMPORTANT_ARTICLE_MODULE} from "./store/modules/modules.types";
    import {GET_IMPORTANT_ARTICLE} from "./store/modules/any/actions.types";
    export default {
        components:{
            'dispatch': Dispatch,
            'questions': Questions,
            'adverts-dogm-contests': AdvertsDogmContests,
            'user-banners': UserBanners,
            'small-banners': SmallBanners,
            'photos-on-main': PhotosOnMain,
            'important-article': ImportantArticle
        },
        created(){
            this.$store.commit('setEoId', document.body.getAttribute('data-eo-id'))
            this.$store.commit('setLevel', document.body.getAttribute('data-level'))
            this.$store.commit("setPathNews", document.getElementById(this.$store.state.container).getAttribute("path-news"))
            this.$store.commit("setPathAdverts", document.getElementById(this.$store.state.container).getAttribute("path-adverts"))
            this.$store.commit("setHost", document.getElementById(this.$store.state.container).getAttribute("data-host") )
            this.$store.commit("setDispatchLink", document.getElementById(this.$store.state.container).getAttribute("data-dispatch-link"))
            this.$store.commit("setApiUserBanners", document.getElementById(this.$store.state.container).getAttribute('data-api-user-banners'))
            this.$store.commit("photosOnMain/setPath", document.getElementById(this.$store.state.container).getAttribute('data-photos-on-main'))
        },
        methods:{
        }
    }
</script>

<style lang="less">
    .important-article{
        .inner{
            a, a:hover{
                text-decoration: none;
            }
        }
    }
    #title-tabs-main{
        li {
            & > a {
                padding: 5px 15px 6px;
                line-height: 15px;

                &.new-tab {
                    color: #fe5e5e
                }

            }
        }
    }
    #tabs-on-main{
        .kris-news-body{
            .kris-news-top{
                .data-saturday{
                    font-size: 12px;
                    color: #7b7b7b;
                    float: left;
                    display: block;
                    margin-top: 0px;
                }
            }
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
        transition-duration: 0.1s;
    }
</style>
