import Vue from 'vue'
import Router from 'vue-router'
import News from './views/News'

Vue.use(Router)

export default new Router({
    routes: [

        {
            path: '/',
            name: 'news',
            component: News
        },

        {
            path: '/adverts',
            name: 'adverts',
            component: () => import('./views/Adverts.vue')
        },

        {
            path: '/news-today',
            name: 'newsToday',
            component: () => import('./views/NewsToday.vue')
        },

        {
            path: '/pupil-saturday',
            name: 'pupilSaturday',
            component: () => import('./views/PupilSaturday.vue')
        },

        {
            path: '/captcha',
            name: 'confirmCaptcha',
            component: () => import('./views/Captcha.vue')
        }
    ]
})
