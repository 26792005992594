<template>
    <div class="row" id="block-info" v-scroll="handleScroll">

        <adverts-dogm v-if="$store.state.level" />
        <contest v-if="typeof(contest.id) !== 'undefined'" />
    </div>
</template>

<script>
    import AdvertsDogm from './AdvertsDogm'
    import Contest from './Contest'
    import {scrollFunc} from "../mixins/common";
    import {mapState} from 'vuex'
    export default {
        name: "AdvertsDogmContests",
        mixins: [scrollFunc],
        computed:{
            ...mapState('Adverts', {
                adverts: state => state.advertsDogm,
                contest: state => state.contest
            })
        },
        components:{
            'adverts-dogm': AdvertsDogm,
            'contest': Contest,
        },
        methods:{
            handleScroll(evt, el){
                if(
                    this.isScrolled(evt, el)
                    && (this.adverts === false || this.contest === false )
                ){
                    this.$store.commit('Adverts/setContest', [])
                    this.$store.dispatch('Adverts/getContest')

                    this.$store.commit('Adverts/setAdvertsDogm', [])
                    this.$store.dispatch('Adverts/getAdvertsDogm')
                }
            }
        }
    }
</script>

<style scoped>

</style>
