<template>
    <div class="row">
        <div class="col-md-12">
            <div class="kris-konkurs" v-scroll="handleScroll">
                <div class="mainHead">
                    <div class="l1">
                        <div class="line"></div>
                    </div>
                    <div class="l2">
                        <div class="h2">
                            <span>Фотогалерея - избранное</span>
                        </div>
                    </div>
                </div>
                <div  v-if="items">
                    <carousel-photos-on-main
                        v-if="items.length > 0"
                        :autoplay="true"
                        :items="3"
                        :loop="true"
                        :dots="true"
                        :nav="false"
                    >
                        <a :href="item.src" v-bind:key="item.id"  v-for="item in items" target="_blank" class="fancybox">
                            <img v-if="item.preview_main" :src="item.preview_main" alt="" :title="item.desc"/>
                            <img v-else :src="'/autothumbs.php?img=' + item.folder + '/' + item.photo_name + '_280_185.' + item.ext" />
                        </a>
                    </carousel-photos-on-main>
                </div>
                <div id="main-photo-gallery" v-else>
                    <div class="spinner-main-photo">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Carousel from 'vue-owl-carousel'
    import {scrollFunc} from "../mixins/common";

    export default {
        name: "PhotosOnMain",
        mixins: [scrollFunc],
        components: {
            'carousel-photos-on-main': Carousel
        },
        computed:{
            ...mapState(
                'photosOnMain', {
                    'items': state => state.data,
                }
            )
        },
        created(){
            // this.$store.dispatch('photosOnMain/getData')
        },
        methods:{
            handleScroll(evt, el){
                if(this.isScrolled(evt, el) && this.items === false){
                    this.$store.commit('photosOnMain/setData', [])
                    this.$store.dispatch('photosOnMain/getData')
                }
            }
        }
    }
</script>

<style lang="less">
    #main-photo-gallery{
        min-height: 100px;
    }

</style>
