<template>
    <div :class=" typeof(contest.id) !== 'undefined' ? 'col-md-8':'col-md-12'" id ="block-dogm" >
        <div class="kris-konkurs-head mainHead">
            <div class="l1">
                <div class="line"></div>
            </div>
            <div class="l2" >
                <div class="h2">
                    <span class="hidden-xs">Информация Департамента образования и науки</span>
                    <span class="visible-xs">Информация ДОНМ</span>
                </div>
            </div>
        </div>
        <div v-if="items">
            <div id="dogm-ads-main">
                <div v-bind:key="val.id" v-for="val in items" class="kris-news-body">
                    <div class="kris-news-top">
                        <div class="h3">
                            <span class="data" >{{val.ru_date}}</span>
                            <a class="link" :href="'/dogm_ads/?nid=' + val.id" v-html="val.title"></a>
                        </div>
                    </div>
                    <div class="kris-news-bot" v-html="val.anons"></div>
                </div>
                <div class="">
                    <a href="/dogm_ads/">Все объявления</a>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="spinner-main-photo">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {dateFunc} from '../mixins/common'

    export default {
        name: "AdvertsDogm",
        mixins: [ dateFunc ],
        computed:{
            ...mapState('Adverts', {
                items: state => state.advertsDogm,
                contest: state => state.contest
            })
        },
        created(){
        },
        methods:{
        }
    }
</script>

<style scoped>

</style>
