<template>
    <div class="tab-pane active">
        <div v-if="!items" class="kris-news-body">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
            <br>
            <br>
        </div>
        <div v-else class="kris-news-body" v-bind:key="item.id" v-for="item in items">
            <news-item
                :date="dateFormatForNews(item.publish_date)"
                :title="item.name"
                :link="item.link"
                :anons="item.anons"
            />
        </div>
        <div class="">
            <a href="/novosti" target="_blank">Все новости</a>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Axios from 'axios'
    import NewsItem from '../components/NewsItem'
    import { dateFunc } from '../mixins/common'

    export default {
        mixins: [dateFunc],
        components: {
            "news-item": NewsItem
        },

        computed:{
            ...mapState({
                "path": "pathNews",
                "items": "dataNews",
                "host": "host"
            })
        },

        created(){
            this.getItems()
        },

        methods:{
            getItems(){
                if(this.items === false){
                    Axios({
                        url: this.path,
                        method: "get",
                    }).then((res)=>{
                        if(res.status == 200){
                            this.$store.commit("setDataNews", res.data)
                        }
                    }).catch(()=>{
                    })
                }
            },
        },

    }
</script>

