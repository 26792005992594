<template>
    <div class="" v-if="items">
        <carousel-small-user-banners
                v-if="typeof(items.smallBanners) !== 'undefined'"
                id="main-banners"
                :autoplay="true"
                :items="4"
                :loop="items.smallBanners.length > 4 ? true:false"
                :dots="false"
                :nav="false"
                :responsive="{0:{items:1}, 768: {items: 3}, 1024: {items: 4}}"
        >
            <a :class="'small-user-banner'" :href="item.link" v-bind:key="item.id" v-for="item in items.smallBanners" target="_blank" rel="nofollow">
                <img :src="item.src" alt="">
            </a>
        </carousel-small-user-banners>
    </div>
    <div class="" v-else>
        <div class="spinner-main-photo">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Carousel from 'vue-owl-carousel'
    export default {
        name: "SmallUserBanners",
        computed:{
            ...mapState('Banners', {
                items: state => state.userBanners
            }),
        },
        components:{
            'carousel-small-user-banners': Carousel
        },
        created(){
        }
    }
</script>

<style lang="less">
    #main-banners a{
        text-align: center;
        padding: 0px 15px;
        img {
            width: 240px !important;
            margin: 0 auto;
        }
    }
</style>
