<template>
    <li>
        <span class="data" v-html="date ? date : ''"></span>
        <p v-html="question"></p>
    </li>
</template>

<script>
    export default {
        name: "Question",
        props: ['date', 'question']
    }
</script>

<style scoped>

</style>
