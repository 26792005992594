import Axios from 'axios'
export const Banners = {
    namespaced: true,
    state:{
        api: {
            main: 'https://banners.mskobr.ru',
            slider: '/data/bannersPositionTimeCache/2/20.json',
            smallBanners: '/data/bannersTimeCache.json'
        },
        slides: false,
        smallBanners: false,
        userBanners: false,
    },
    mutations:{
        setSlides(state, value){
            state.slides = value
        },
        setSmallBanners(state, value){
            state.smallBanners = value
        },
        setUserBanners(state, value){
            state.userBanners = value
        }
    },
    actions:{
        getSlides({state, commit, rootState}){
            const path = state.api.main + state.api.slider;
            Axios({
                url: path,
                method: 'get'
            }).then((result)=>{
                let data = JSON.parse(result.data),
                now = new Date(),
                res = [];
                for(var i in data){
                    let start_time = new Date(data[i].start_time_unix*1000);
                    let end_time = new Date(data[i].end_time_unix*1000);
                    if(start_time < now && now < end_time){
                        res.push(data[i]);
                    }
                }
                commit('setSlides', res)
            })
        },
        getUserBanners({state, commit, rootState}){
            const path = rootState.apiUserBanners
            Axios({
                'url': path,
                method: 'get'
            }).then((res)=>{
                if(res.data.response === 'success'){
                    commit('setUserBanners', res.data.result)
                }
            })
        },
        getSmallBanners({state, commit, rootState}){
            const path = state.api.main + state.api.smallBanners
            Axios({
                url: path,
                method: 'get'
            }).then((result)=>{
                const now = new Date();
                const data = JSON.parse(result.data)
                let res = [];
                for (var i in data) {
                    let start_time = new Date(data[i].start_time_unix * 1000);
                    let end_time = new Date(data[i].end_time_unix * 1000);
                    if (start_time < now && now < end_time) {
                        res.push(data[i]);
                    }
                }
                commit('setSmallBanners', res)
            })
        }
    }
}
