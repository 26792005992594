import Axios from 'axios'
export const faqNew = {
    namespaced: true,
    state: {
        api: {
            host: 'https://ask.mskobr.ru',
            questions: '/api/faq/question/list/questions.json',
        },
        questionsOnMain: false
    },
    mutations:{
        setQuestionsOnMain(state, value){
            state.questionsOnMain = value
        }
    },
    actions:{
        getQuestionsOnMain({state, commit, rootState}){
            const path = state.api.host + state.api.questions + '?eoId=' + rootState.eoId + '&limit=2&page=0'
            Axios({
                url: path,
                method: 'get'
            }).then((res)=>{
                if(res.data.result == 'success'){
                    commit('setQuestionsOnMain', res.data.data)

                }
            })
        }
    }
}
