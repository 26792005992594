<template>
    <div class="kris-konkurs-head mainHead">
        <div class="l1">
            <div class="line"></div>
        </div>
        <div class="l2">
            <div class="h2">
                <span>Вопрос - ответ</span>
                <a :href="link" title="Задать вопрос">Задать вопрос</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        props: ['link'],
    }
</script>

<style scoped>

</style>
