import Axios from 'axios'
export const Adverts = {
    namespaced: true,
    state:{
        api:{
            host: 'https://banners.mskobr.ru',
            advertsDogm:'https://banners.mskobr.ru/data/getAdverts/edu/',
            contest: 'https://banners.mskobr.ru/data/getContests/edu/'
        },
        advertsDogm: false,
        contest: false
    },
    mutations:{
        setAdvertsDogm(state, value){
            state.advertsDogm = value
        },
        setContest(state, value){
            state.contest = value
        }
    },
    actions:{
        getAdvertsDogm({state, commit, rootState}){
            const path = state.api.advertsDogm + rootState.level + '/4/1/0.json'
            Axios({
                url: path,
                method: 'get'
            }).then((result) => {
                let data = JSON.parse(result.data),
                res = [],
                now = new Date()
                const monthA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(",");
                for(let i in data){
                    let start_time = new Date(data[i].start_time_unix*1000);
                    let end_time = new Date(data[i].end_time_unix*1000);
                    if(start_time < now && now < end_time){
                        data[i].ru_date = start_time.getDate()+" "+monthA[start_time.getMonth()]+" "+start_time.getFullYear();
                        res.push(data[i]);
                    }
                }
                commit('setAdvertsDogm', res)
            })
        },
        getContest({state, commit, rootState}){
            const path = state.api.contest + rootState.level + '/1/1/0.json'
            Axios({
                url: path,
                method: 'get'
            }).then((result) => {
                const monthA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(",")
                var now = new Date();
                let data = JSON.parse(result.data),
                res = [];
                for(let i in data){
                    const start_time = new Date(data[i].start_time_unix*1000);
                    const end_time = new Date(data[i].end_time_unix*1000);
                    if(start_time < now && now < end_time){
                        data[i].ru_date = start_time.getDate()+" "+monthA[start_time.getMonth()]+" "+start_time.getFullYear();
                        res = data[i];
                        break;
                    }
                }
                commit('setContest', res)
            })
        }
    }
}
