import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import {Banners} from './modules/banners'
import {Adverts} from "./modules/adverts";
import {photosOnMain, importantAdvert} from "./modules/any/index"
import { faqNew } from "./modules/faqNew";
import {SET_NEWSLETTER_EMAIL} from "./mutations.types";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        eoId: false,
        level: false,
        container: "app-page-index",
        host: "",
        pathNews: "",
        pathAdverts: "",
        newsToday: 'https://news.mosobr.tv/api/v0/posts_group_by_date',
        dispatchLink: "",
        api: 'https://api-sites.mskobr.ru',
        questions: '/site-api/faq/main/questions.json',
        apiUserBanners: '',
        dataNews: false,
        dataAdverts: false,
        dataNewsToday: false,
        dataSaturdayEvents: false,
        dataQuestions: false,
        faqNewIds: [
            // 12430,
            9292,
            9310,
            9311,
            12653,
            13473,
            12474,
            9431,
            13781,
            13671,
            13677
        ],
        newsletterEmail: null
    },

    mutations: {
        [SET_NEWSLETTER_EMAIL](state, value){
          state.newsletterEmail = value
        },
        setEoId(state, value){
            state.eoId = value
        },

        setLevel(state, value){
            state.level = value
        },

        setHost(state, value){
            state.host = value
        },

        setPathNews(state, value){
            state.pathNews = value
        },

        setPathAdverts(state, value){
            state.pathAdverts = value
        },

        setDataNews(state, value){
            state.dataNews = value
        },

        setDataAdverts(state, value){
            state.dataAdverts = value
        },

        setDataNewsToday(state, value){
            state.dataNewsToday = value
        },

        setDispatchLink(state, value){
            state.dispatchLink = value
        },

        setDataQuestions(state, value){
            state.dataQuestions = value
        },

        setApiUserBanners(state, value){
            state.apiUserBanners = value
        }
    },

    actions: {
        getDataQuestions({ state, commit }){
            const path = state.api + state.questions + '?eoId=' + state.eoId
            Axios({
                url: path,
                method: 'get'
            }).then((res)=>{
                if (res.data.response == 'success') {
                    commit('setDataQuestions', res.data.result)
                }
            })
        },
    },

    modules:{
        Banners,
        Adverts,
        photosOnMain,
        faqNew,
        importantAdvert
    }
})
