<template>
    <div class="row" v-scroll="handleScroll">
        <div v-if="items"  class="" id="static_banners">
            <div class="static_banners">
                <span v-for="(item, index) in items" class="col-md-2" :id="'banner' + index" :key="index">
                    <a :href="item.link" target="_blank" rel="nofollow">
                        <img :src="host +'/'+ item.file" />
                    </a>
                </span>
            </div>
        </div>
        <div v-else>
            <br>
            <div class="spinner-main-photo">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {scrollFunc} from "../mixins/common";

    export default {
        name: "SmallBanners",
        mixins: [scrollFunc],
        computed:{
            ...mapState('Banners', {
                items: state => state.smallBanners,
                host: state => state.api.main
            }),
        },
        methods:{
            handleScroll(evt, el){
                if(this.isScrolled(evt, el) && this.items === false){
                    this.$store.commit('Banners/setSmallBanners', [])
                    this.$store.dispatch('Banners/getSmallBanners')
                }
            }
        }
    }
</script>

<style scoped>

</style>
