export const dateFunc = {
    methods: {

        dateFormatForNews: function (str) {
            str = this.prepareDate(str)
            let date = new Date();
            date.setTime(Date.parse(str))
            date.toUTCString()
            let day = this.addZero(date.getDate())
            let month = this.addZero(date.getMonth() + 1)
            return day + '.' + month + '.' + date.getFullYear()
        },

        timeFormatForNews: function (str) {
            let date = this.parseDate(str)
            return this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes())
        },

        addZero: function (value) {
            if( value < 10 ){
                value = '0' + value
            }
            return value
        },

        prepareDate: function(str){
            if(typeof(str) === 'string' && str.indexOf("T") !== false){
                let res = str.split("T")
                return res[0]
            }else
                return str
        },

        parseDate: function(date) {
            if (!date) {
                return null
            }
            let res = date.replace(' UTC', '')
            if(res.indexOf('T') === false){
                res.replace(' ', 'T')
            }
            if(res.indexOf('Z') === false){
                res += 'Z'
            }
            return new Date(res)
        },

        getDateRu(unix_timestamp){
            if(typeof (unix_timestamp) === 'undefined') return false
            let monthA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(",");
            let input_date = new Date(unix_timestamp*1000);
            let result = input_date.getDate() + " " + monthA[input_date.getMonth()] + " " + input_date.getFullYear();
            return result;
        },

        getDateRuFromDate(dateStr){
            let input_date = this.parseDate(dateStr)
            let monthA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(",");
            let result = input_date.getDate() + " " + monthA[input_date.getMonth()] + " " + input_date.getFullYear();
            return result;
        }

    },
}

export const scrollFunc = {
    methods: {
        isScrolled (evt, el) {
            var targetPosition = {
                top: window.pageYOffset + el.getBoundingClientRect().top,
                left: window.pageXOffset + el.getBoundingClientRect().left,
                right: window.pageXOffset + el.getBoundingClientRect().right,
                bottom: window.pageYOffset + el.getBoundingClientRect().bottom
            },
            windowPosition = {
                top: window.pageYOffset,
                left: window.pageXOffset,
                right: window.pageXOffset + document.documentElement.clientWidth,
                bottom: window.pageYOffset + document.documentElement.clientHeight
            };
            if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
                targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
                targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
                targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
                return true
            }else{
                return false
            }
            // return window.pageYOffset >= el.getBoundingClientRect().top
        }
    }
}
